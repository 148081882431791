import React from "react";
import { Link, StaticQuery, graphql } from 'gatsby';

import Container from '../container';
import { pathTo } from '../../routes';

import styles from './index.module.scss';
import logo from '../../assets/images/logo-white.svg';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.row}>
          <div className={styles.news}>
            <div className={styles.headline}>Über uns</div>
            <div className={styles.text}>Wir stehen für eine sorgenfreie digitale Zukunft.<br/>&mdash;<br/>Dank unserer digitalen Infrastruktur können wir effizente Prozesse abbilden. Der regelmäßige persönliche Kontakt mit unseren Mandanten hat trotzdem oberste Priorität.</div>
            <div className={styles.logo}>
              <img
                src={logo}
                alt='Advice - Steuerberatung in Dresden'
                title="Advice - Steuerberatung in Dresden"
              />
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.contact}>
              <div className={styles.headline}>advice Steuerberatungs- gesellschaft mbH</div>
              <div className={styles.text}>
                Peter Rost<br/><br/>
                Großenhainer Str. 32<br/>
                01097 Dresden Neustadt<br/>
                <br/>
                Reichsstr. 22<br/>
                09112 Chemnitz
              </div>
              <div className={styles.headline}>Telefon</div>
              <div className={styles.text}>+49 351 / 811 97 500</div>
              <div className={styles.headline}>E-Mail</div>
              <div className={styles.text}>info@advice-steuerberatung.de</div>
            </div>
            <div className={styles.navigation}>
              <StaticQuery
                query={graphql`
                  query MetaNavigationQuery {
                    meta: contentfulNavigationZone(title: {eq: "Meta"}) {
                      items {
                        __typename
                        ... on ContentfulPage {
                          id
                          slug
                          title
                        }
                      }
                    }
                  }
                `}
                render={data => {
                  if (data === undefined || data.meta === undefined) return null;

                  return [...data.meta.items].map(item => (
                    <Link
                      key={item.id}
                      to={pathTo(item)}
                      className={styles.link}
                    >{item.title}</Link>
                  ))
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
};

export default Footer;
