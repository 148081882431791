import React, { useState, useEffect } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import ClickOutside from '../clickoutside';

import styles from './index.module.scss';

const Modal = ({ children, isOpen=false, canClose=true, canLockBody=false }) => {
  const [open, setOpen] = useState(isOpen);
  const toggleState = () => setOpen(!open);

  useEffect(() => {
    if (open && canLockBody) {
      disableBodyScroll('body');
    } else {
      clearAllBodyScrollLocks();
    }
  }, [open]);

  const toggle = {
    ...children[0],
    props: {
      ...children[0].props,
      onClick: toggleState
    }
  }

  const body = () => {
    if (canClose) {
      return (
        <ClickOutside onClick={toggleState}>
          <div className={styles.content}>
            {children.slice(1,children.length)}
          </div>
        </ClickOutside>
      )
    } else {
      return (
        <div className={styles.content}>
          {children.slice(1,children.length)}
        </div>
      )
    }
  }

  return (
    <>
      {toggle}
      {( open &&
        <div className={styles.wrapper}>
          <div className={styles.modal}>
            {( canClose &&
              <div className={styles.close}>
                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-5x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg>
              </div>
            )}
            {body()}
          </div>
          <div className={styles.backdrop}/>
        </div>
      )}
    </>
  )
}

export default Modal;
